var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-setting-field-select" },
    [
      _c("label", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.settings.title)),
      ]),
      _c("v-select", {
        staticClass: "mb-4",
        attrs: { value: _vm.selectedValue, options: _vm.options, multiple: "" },
        on: { input: _vm.updateSettings },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }