var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-setting-field-html" },
    [
      _c("label", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.settings.title)),
      ]),
      _vm.settings.description
        ? _c("div", { staticClass: "text-sm mt-2 mb-2" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.settings.description) },
            }),
          ])
        : _vm._e(),
      _c("vs-textarea", {
        staticClass: "w-full",
        attrs: { value: _vm.value, rows: "4" },
        on: { change: _vm.onTextChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }