var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4" },
    [
      _c("h5", { staticClass: "muted" }, [
        _vm._v("Public Referrers (Widgets)"),
      ]),
      _c(
        "vs-button",
        {
          staticClass: "mt-2",
          attrs: { size: "small", color: "primary", type: "filled" },
          on: {
            click: function ($event) {
              return _vm.showAddReferrerlDialog(false)
            },
          },
        },
        [_vm._v("Add")]
      ),
      _c(
        "div",
        { staticClass: "p-4" },
        [
          _c("security-settings-app-referrers", {
            attrs: { appReferrers: _vm.publicReferrers, isPrivate: false },
            on: {
              onEditReferrer: _vm.handleEditReferrer,
              onDeleteReferrer: _vm.handleDeleteReferrer,
            },
          }),
        ],
        1
      ),
      _vm.appAllowPrivate
        ? _c("h5", { staticClass: "mt-4 muted" }, [
            _vm._v("Private Referrers (API Access)"),
          ])
        : _vm._e(),
      _vm.appAllowPrivate
        ? _c(
            "vs-button",
            {
              staticClass: "mt-2",
              attrs: { size: "small", color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.showAddReferrerlDialog(true)
                },
              },
            },
            [_vm._v("Add")]
          )
        : _vm._e(),
      _vm.appAllowPrivate
        ? _c(
            "div",
            { staticClass: "p-4" },
            [
              _c("security-settings-app-referrers", {
                attrs: { appReferrers: _vm.privateReferrers, isPrivate: true },
                on: {
                  onEditReferrer: _vm.handleEditReferrer,
                  onDeleteReferrer: _vm.handleDeleteReferrer,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showReferrerDialog
        ? _c("app-referrers-form-dialog", {
            attrs: {
              applicationId: _vm.applicationId,
              host: _vm.hostForEdit,
              isPrivate: _vm.isPrivateForEdit,
              isEditMode: _vm.isEditMode,
            },
            on: {
              onSuccessAdd: _vm.handleSuccessAdd,
              onSuccessEdit: _vm.handleSuccessEdit,
              onCancel: function ($event) {
                _vm.showReferrerDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }