var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3", pageTitle: "Application Settings" } },
    [
      !_vm.dataFetching
        ? _c(
            "vx-card",
            [
              _vm.activeApplication
                ? _c(
                    "vs-tabs",
                    {
                      key: _vm.isSmallerScreen,
                      staticClass: "tabs-shadow-none",
                      attrs: { position: "left", id: "profile-tabs" },
                      model: {
                        value: _vm.selectedTabIndex,
                        callback: function ($$v) {
                          _vm.selectedTabIndex = $$v
                        },
                        expression: "selectedTabIndex",
                      },
                    },
                    [
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-settings",
                            label: !_vm.isSmallerScreen ? "General" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tab-general md:ml-0 md:mt-0 mt-0 ml-0",
                            },
                            [
                              _c("general-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-map-pin",
                            label: !_vm.isSmallerScreen ? "Location" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-0 ml-0" },
                            [
                              _c("location-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-calendar",
                            label: !_vm.isSmallerScreen
                              ? "Booking Contacts"
                              : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-4" },
                            [
                              _c("booking-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-info",
                            label: !_vm.isSmallerScreen
                              ? "Support Contacts"
                              : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-4" },
                            [
                              _c("support-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-globe",
                            label: !_vm.isSmallerScreen
                              ? "Regional Settings"
                              : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-0 ml-0" },
                            [
                              _c("regional-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-more-vertical",
                            label: !_vm.isSmallerScreen
                              ? "General Settings"
                              : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-0 ml-0" },
                            [
                              _c("generic-settings", {
                                attrs: { applicationId: _vm.applicationId },
                                on: {
                                  updateAppSettings:
                                    _vm.reloadCurrentAppSettings,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-more-vertical",
                            label: !_vm.isSmallerScreen ? "Email Settings" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-0 ml-0" },
                            [
                              _c("generic-settings", {
                                attrs: {
                                  applicationId: _vm.applicationId,
                                  "setting-section-id": 1,
                                },
                                on: {
                                  updateAppSettings:
                                    _vm.reloadCurrentAppSettings,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-code",
                            label: !_vm.isSmallerScreen ? "API Keys" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-4" },
                            [
                              _c("api-key-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-lock",
                            label: !_vm.isSmallerScreen ? "Security" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tab-general md:mt-0 mt-4" },
                            [
                              _c("security-settings", {
                                attrs: { applicationId: _vm.applicationId },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }