var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-setting-field-arraylinks" },
    [
      _c("label", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.settings.title)),
      ]),
      _c("ag-grid-vue", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value && _vm.value.length && _vm.value.length > 0,
            expression: "value && value.length && value.length > 0",
          },
        ],
        ref: "agGridTable",
        staticClass: "ag-theme-material w-100",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.rowData,
          gridOptions: _vm.gridOptions,
          rowDragManaged: true,
          animateRows: true,
          context: _vm.context,
          headerHeight: 0,
          rowHeight: _vm.rowHeight,
          domLayout: _vm.domLayout,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c(
            "vs-button",
            {
              staticClass: "w-full",
              attrs: { color: "primary" },
              on: { click: _vm.onAddFile },
            },
            [_vm._v(_vm._s(_vm.addButtonCaption))]
          ),
        ],
        1
      ),
      _vm.showAddModal && _vm.newFile
        ? _c("travio-modal", { attrs: { width: "700", height: "200" } }, [
            _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v(_vm._s("Add ArrayLink")),
            ]),
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  key: "add-text",
                  staticClass: "w-full mt-4",
                  attrs: { label: "Text", name: "New Text" },
                  model: {
                    value: _vm.newFile.Text,
                    callback: function ($$v) {
                      _vm.$set(_vm.newFile, "Text", $$v)
                    },
                    expression: "newFile.Text",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("New Text"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        url: { require_protocol: false },
                      },
                      expression:
                        "{required: true, url: {require_protocol: false }}",
                    },
                  ],
                  key: "add-url",
                  staticClass: "w-full mt-4",
                  attrs: { label: "Url", name: "New Url" },
                  model: {
                    value: _vm.newFile.Url,
                    callback: function ($$v) {
                      _vm.$set(_vm.newFile, "Url", $$v)
                    },
                    expression: "newFile.Url",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("New Url"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex flex-wrap justify-center",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.onSaveAdd },
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-2",
                    attrs: { color: "danger", type: "filled" },
                    on: { click: _vm.onCancelAdd },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showEditModal
        ? _c("travio-modal", { attrs: { width: "700", height: "250" } }, [
            _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("Edit Array Link"),
            ]),
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  key: "edit-text",
                  staticClass: "w-full mt-4",
                  attrs: { label: "Text", name: "Text" },
                  model: {
                    value: _vm.rowData[_vm.editingRowIndex].Text,
                    callback: function ($$v) {
                      _vm.$set(_vm.rowData[_vm.editingRowIndex], "Text", $$v)
                    },
                    expression: "rowData[editingRowIndex].Text",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Text"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        url: { require_protocol: false },
                      },
                      expression:
                        "{required: true, url: {require_protocol: false }}",
                    },
                  ],
                  key: "edit-url",
                  staticClass: "w-full mt-4",
                  attrs: { label: "Url", name: "Url" },
                  model: {
                    value: _vm.rowData[_vm.editingRowIndex].Url,
                    callback: function ($$v) {
                      _vm.$set(_vm.rowData[_vm.editingRowIndex], "Url", $$v)
                    },
                    expression: "rowData[editingRowIndex].Url",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Url"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex flex-wrap justify-center",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.onSaveEdit },
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-2",
                    attrs: { color: "danger", type: "filled" },
                    on: { click: _vm.onCancelEdit },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }