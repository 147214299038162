var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.form.allowPublicApiKey && !_vm.form.allowPrivateApiKey
      ? _c("div", { staticClass: "mt-4" }, [
          _c("span", [_vm._v("API Keys are hidden")]),
        ])
      : _vm._e(),
    _vm.form.allowPublicApiKey || _vm.form.allowPrivateApiKey
      ? _c("div", { staticClass: "vx-row mt-4" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { name: "Application ID", disabled: true },
                model: {
                  value: _vm.applicationId,
                  callback: function ($$v) {
                    _vm.applicationId = $$v
                  },
                  expression: "applicationId",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.form.allowPublicApiKey
      ? _c("div", { staticClass: "vx-row mt-4" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Public API Key", disabled: true },
                model: {
                  value: _vm.form.publicApiKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "publicApiKey", $$v)
                  },
                  expression: "form.publicApiKey",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Public API Key"))),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.form.allowPublicApiKey
      ? _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mt-4 mr-4",
                attrs: {
                  type: "border",
                  color: "danger",
                  "icon-pack": "feather",
                  icon: "icon-refresh-cw",
                  title: "Regenerate public api key",
                },
                on: { click: _vm.regeneratePublicApi },
              },
              [
                _vm._v(
                  _vm._s(_vm.isSmallerScreen ? "" : "Regenerate") + "\n    "
                ),
              ]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.form.publicApiKey,
                    expression: "form.publicApiKey",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onCopy,
                    expression: "onCopy",
                    arg: "success",
                  },
                ],
                staticClass: "mt-4",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-clipboard",
                  title: "Copy To Clipboard",
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.isSmallerScreen ? "" : "Copy To Clipboard") +
                    "\n    "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.form.allowPrivateApiKey
      ? _c("div", { staticClass: "vx-row mt-12" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Private API Key", disabled: true },
                model: {
                  value: _vm.form.privateApiKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "privateApiKey", $$v)
                  },
                  expression: "form.privateApiKey",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Private API Key"))),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.form.allowPrivateApiKey
      ? _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mt-4 mr-4",
                attrs: {
                  type: "border",
                  color: "danger",
                  "icon-pack": "feather",
                  icon: "icon-refresh-cw",
                  title: "Regenerate private api key",
                },
                on: { click: _vm.regeneratePrivateApi },
              },
              [
                _vm._v(
                  _vm._s(_vm.isSmallerScreen ? "" : "Regenerate") + "\n    "
                ),
              ]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.form.privateApiKey,
                    expression: "form.privateApiKey",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onCopy,
                    expression: "onCopy",
                    arg: "success",
                  },
                ],
                staticClass: "mt-4",
                attrs: {
                  title: "Copy To Clipboard",
                  "icon-pack": "feather",
                  icon: "icon-clipboard",
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.isSmallerScreen ? "" : "Copy To Clipboard") +
                    "\n    "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v("Application ID")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v("Public API Key")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
      _c("span", [_vm._v("Private API Key")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }