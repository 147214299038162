var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-setting-field-color mt-6" },
    [
      _c("label", { staticClass: "text-sm" }, [
        _vm._v(_vm._s(_vm.settings.title)),
      ]),
      _c(
        "button",
        {
          staticClass: "button-picker w-full",
          style: {
            border: "1px solid " + _vm.textColor,
            backgroundColor: _vm.colorValue,
          },
          on: {
            click: function ($event) {
              _vm.showColorPicker = true
            },
          },
        },
        [
          _c("span", { style: { color: _vm.textColor } }, [
            _vm._v("SET COLOUR"),
          ]),
        ]
      ),
      _vm.showColorPicker
        ? _c("travio-modal", { attrs: { width: "350", height: "420" } }, [
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              [
                _c("Chrome", {
                  attrs: {
                    value: _vm.colorValue,
                    "preset-colors": [
                      "#f00",
                      "#00ff00",
                      "#00ff0055",
                      "rgb(201, 76, 76)",
                      "rgba(0,0,255,1)",
                      "hsl(89, 43%, 51%)",
                      "hsla(89, 43%, 51%, 0.6)",
                    ],
                  },
                  on: { input: _vm.updateSettings },
                }),
                _c("Swatches", {
                  attrs: { value: _vm.colorValue },
                  on: { input: _vm.updateSettings },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex flex-wrap justify-center",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "w-full",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v("OK")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }