var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-setting-field-url" }, [
    _c("label", { staticClass: "text-sm" }, [
      _vm._v(_vm._s(_vm.settings.title)),
    ]),
    _c("input", {
      directives: [
        {
          name: "validate",
          rawName: "v-validate",
          value: { url: { require_protocol: false } },
          expression: "{url: {require_protocol: false }}",
        },
      ],
      staticClass: "w-full text-sm",
      attrs: { type: "text", name: _vm.settings.title },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.updateSettings($event.target.value)
        },
      },
    }),
    _c("span", { staticClass: "text-danger text-sm" }, [
      _vm._v(_vm._s(_vm.errors.first(_vm.settings.title))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }