var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(Object.keys(_vm.categorizedSettings), function (category, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("h3", { staticClass: "text-primary mt-5 mb-1" }, [
              _vm._v(_vm._s(category)),
            ]),
            _c("hr", { attrs: { size: "1" } }),
            _vm._l(
              _vm.categorizedSettings[category],
              function (formField, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "mt-4" },
                  [
                    _c(
                      formField.component,
                      _vm._b(
                        {
                          tag: "component",
                          model: {
                            value: formField.value,
                            callback: function ($$v) {
                              _vm.$set(formField, "value", $$v)
                            },
                            expression: "formField.value",
                          },
                        },
                        "component",
                        { settings: formField.settings },
                        false
                      )
                    ),
                  ],
                  1
                )
              }
            ),
          ],
          2
        )
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-4 mr-4",
              attrs: { type: "border", color: "danger" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }