var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("label", { staticClass: "text-sm" }, [
          _vm._v(
            "Currencies - must also be supported by your payment provider(s)"
          ),
        ]),
        _c("v-select", {
          attrs: {
            value: _vm.selectedCurrencies,
            "append-to-body": "",
            multiple: "",
            options: _vm.currencyOptions,
          },
          on: { input: _vm.setSelectedCurrencies },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("label", { staticClass: "text-sm" }, [_vm._v("Languages")]),
        _c("v-select", {
          attrs: {
            value: _vm.selectedLanguages,
            "append-to-body": "",
            multiple: "",
            options: _vm.languageOptions,
          },
          on: { input: _vm.setselectedLanguages },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("label", { staticClass: "text-sm" }, [_vm._v("Nationalities")]),
        _c("v-select", {
          attrs: {
            value: _vm.selectedNationalities,
            "append-to-body": "",
            multiple: "",
            options: _vm.nationalityOptions,
          },
          on: { input: _vm.setSelectedNationalities },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex flex-wrap justify-end" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-4 mr-4",
            attrs: { type: "border", color: "danger" },
            on: { click: _vm.handleCancel },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "vs-button",
          { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
          [_vm._v("Save")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }