var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:250",
            expression: "'max:250'",
          },
        ],
        staticClass: "w-full mt-2",
        attrs: { label: "Address 1", name: "address1" },
        model: {
          value: _vm.form.address1,
          callback: function ($$v) {
            _vm.$set(_vm.form, "address1", $$v)
          },
          expression: "form.address1",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("address1"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:250",
            expression: "'max:250'",
          },
        ],
        staticClass: "w-full mt-4",
        attrs: { label: "Address 2", name: "address2" },
        model: {
          value: _vm.form.address2,
          callback: function ($$v) {
            _vm.$set(_vm.form, "address2", $$v)
          },
          expression: "form.address2",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("address2"))),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "max:50",
                  expression: "'max:50'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "City/Town/Area", name: "city" },
              model: {
                value: _vm.form.city,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "city", $$v)
                },
                expression: "form.city",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("city"))),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "max:50",
                  expression: "'max:50'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "State/Postal Code", name: "state" },
              model: {
                value: _vm.form.state,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "state", $$v)
                },
                expression: "form.state",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("state"))),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Country")]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedCountry,
              "append-to-body": "",
              options: _vm.countryOptions,
            },
            on: { input: _vm.setSelectedCountry },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-4 mr-4",
              attrs: { type: "border", color: "danger" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg:float-left mt-4" }, [
      _c("span", { staticClass: "text-sm text-danger" }, [
        _vm._v("*Required Field"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }