import { render, staticRenderFns } from "./GenericSettings.vue?vue&type=template&id=7296db66"
import script from "./GenericSettings.vue?vue&type=script&lang=js"
export * from "./GenericSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/src/AgendasLtd.TravioPro.Vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7296db66')) {
      api.createRecord('7296db66', component.options)
    } else {
      api.reload('7296db66', component.options)
    }
    module.hot.accept("./GenericSettings.vue?vue&type=template&id=7296db66", function () {
      api.rerender('7296db66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/company-applications/components/GenericSettings.vue"
export default component.exports