var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showAppReferrerDialog,
          expression: "showAppReferrerDialog",
        },
      ],
      attrs: { width: "430", height: "100", zIndex: "99999" },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("App Referrer"),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row mt-2" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Host")]),
              _vm.isPrivate
                ? _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|mustBeValidIpAddress|noNewLine",
                        expression: "'required|mustBeValidIpAddress|noNewLine'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { name: "Host", type: "text" },
                    model: {
                      value: _vm.hostValue,
                      callback: function ($$v) {
                        _vm.hostValue = $$v
                      },
                      expression: "hostValue",
                    },
                  })
                : _vm._e(),
              _vm.isPrivate
                ? _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Host"))),
                  ])
                : _vm._e(),
              !_vm.isPrivate
                ? _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|mustBeValidDomainName|noNewLine",
                        expression:
                          "'required|mustBeValidDomainName|noNewLine'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { name: "Host", type: "text" },
                    model: {
                      value: _vm.hostValue,
                      callback: function ($$v) {
                        _vm.hostValue = $$v
                      },
                      expression: "hostValue",
                    },
                  })
                : _vm._e(),
              !_vm.isPrivate
                ? _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Host"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-center",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: {
                disabled: _vm.errors.any(),
                color: "primary",
                type: "filled",
              },
              on: { click: _vm.save },
            },
            [_vm._v("Save")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "danger", type: "border" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }