var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:50",
            expression: "'max:50'",
          },
        ],
        staticClass: "w-full mt-4",
        attrs: {
          label: "Support Email Label",
          placeholder: "eg. Email Booking Support",
          name: "supportEmailName",
        },
        model: {
          value: _vm.form.supportEmailName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "supportEmailName", $$v)
          },
          expression: "form.supportEmailName",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("supportEmailName"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:100|email",
            expression: "'max:100|email'",
          },
        ],
        staticClass: "w-full mt-4",
        attrs: {
          label: "Support Email Address",
          placeholder: "eg. bookings@domain.com",
          name: "supportEmail",
        },
        model: {
          value: _vm.form.supportEmail,
          callback: function ($$v) {
            _vm.$set(_vm.form, "supportEmail", $$v)
          },
          expression: "form.supportEmail",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("supportEmail"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:150",
            expression: "'max:150'",
          },
        ],
        staticClass: "w-full mt-4",
        attrs: {
          label: "Support Telephone Label",
          placeholder: "eg. Call Booking Support",
          name: "supportTelephoneName",
        },
        model: {
          value: _vm.form.supportTelephoneName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "supportTelephoneName", $$v)
          },
          expression: "form.supportTelephoneName",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("supportTelephoneName"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:20",
            expression: "'max:20'",
          },
        ],
        staticClass: "w-full mt-4",
        attrs: {
          label: "Support Telephone Number",
          placeholder: "eg. 0123456789",
          name: "supportTelephone",
        },
        model: {
          value: _vm.form.supportTelephone,
          callback: function ($$v) {
            _vm.$set(_vm.form, "supportTelephone", $$v)
          },
          expression: "form.supportTelephone",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("supportTelephone"))),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-4 mr-4",
              attrs: { type: "border", color: "danger" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg:float-left mt-4" }, [
      _c("span", { staticClass: "text-sm text-danger" }, [
        _vm._v("*Required Field"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }