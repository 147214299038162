var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center mt-4" },
        [
          _c("vs-avatar", {
            staticClass: "mr-4 mb-4 company-logo-avatar",
            attrs: {
              src: _vm.form.logoUrl,
              icon: "cloud_upload",
              size: "70px",
            },
            on: {
              click: function ($event) {
                return _vm.$refs.companyLogoInput.click()
              },
            },
          }),
          _c(
            "div",
            [
              _c("input", {
                ref: "companyLogoInput",
                attrs: { type: "file", hidden: "", id: "companyLogoInput" },
                on: { change: _vm.handleFileUpload },
              }),
              _c(
                "vs-button",
                {
                  staticClass: "mr-4 sm:mb-0 mb-2",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.companyLogoInput.click()
                    },
                  },
                },
                [_vm._v("Upload App Logo")]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.removeLogo },
                },
                [_vm._v("Remove")]
              ),
              _c("p", { staticClass: "text-sm mt-2" }, [
                _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:50",
            expression: "'max:50'",
          },
        ],
        staticClass: "w-full mt-2",
        attrs: { label: "Application Name*", name: "applicationName" },
        model: {
          value: _vm.form.applicationName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "applicationName", $$v)
          },
          expression: "form.applicationName",
        },
      }),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "max:200|email",
            expression: "'max:200|email'",
          },
        ],
        staticClass: "w-full mt-2",
        attrs: {
          label: "System Notifications Sent To",
          name: "System Notifications Sent To",
        },
        model: {
          value: _vm.form.systemNotificationsSentTo,
          callback: function ($$v) {
            _vm.$set(_vm.form, "systemNotificationsSentTo", $$v)
          },
          expression: "form.systemNotificationsSentTo",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("System Notifications Sent To"))),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [
            _vm._v("Data Centre Region*"),
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            attrs: {
              value: _vm.selectedRegion,
              disabled: true,
              "append-to-body": "",
              options: _vm.regionOptions,
              name: "Region",
            },
            on: { input: _vm.setSelectedRegion },
          }),
          _c("span", { staticClass: "text-danger text-sm" }, [
            _vm._v(_vm._s(_vm.errors.first("Region"))),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Web Platform")]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedWebPlatform,
              "append-to-body": "",
              options: _vm.webPlatformOptions,
            },
            on: { input: _vm.setSelectedWebPlatform },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 mb-8" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Subscription*")]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedSubscription,
              disabled: true,
              "append-to-body": "",
              options: _vm.subscriptionOptions,
            },
            on: { input: _vm.setSelectedSubscription },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-4 mr-4",
              attrs: { type: "border", color: "danger" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg:float-left mt-4" }, [
      _c("span", { staticClass: "text-sm text-danger" }, [
        _vm._v("*Required Field"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }