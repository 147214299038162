var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material mb-3",
        style: { height: "232px" },
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.appReferrers,
          gridOptions: _vm.gridOptions,
          domLayout: "normal",
          rowSelection: "single",
          pagination: false,
          suppressPaginationPanel: true,
          context: _vm.context,
        },
        on: { "grid-size-changed": _vm.onGridSizeChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }